import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TextInput, HelperText, useTheme } from 'react-native-paper'
import PropTypes from 'prop-types'

const EmailTextInput = (props) => {
  const { colors } = useTheme()
  const { disabled = false, onChangeText, isValidEmail = false } = props
  return (
    <View style={disabled && styles.disabled}>
      <TextInput
        {...props}
        onChangeText={(text) => {
          onChangeText(text)
        }}
        placeholderTextColor={colors.onSurfacePlaceholder}
        disabled={disabled}
        theme={{
          colors: {
            onSurface: colors.surface,
          },
        }}
      />
      {!isValidEmail && (
        <HelperText type='error'>Email address is invalid!</HelperText>
      )}
    </View>
  )
}

EmailTextInput.defaultProps = {
  errorMessage: 'Email address is invalid!',
}

EmailTextInput.propTypes = {
  onValidate: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

const styles = StyleSheet.create({
  disabled: {
    borderColor: '#808080',
    borderWidth: '1px',
    borderRadius: 4,
    marginTop: 5,
    height: 40,
  },
})

export default EmailTextInput
