import React, { useRef } from 'react'
import { SafeAreaView, Platform } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { ThemeProvider } from 'theme-provider/src/index'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'react-redux'
import { AppNavigator } from './navigation'
import { navigationRef } from './navigation/RootNavigator'
import Analytics from './utils/analytics'
import { store } from './store/storeProvider'
import Loader from './components/Loader'

if (__DEV__) {
  console.log('Development')
} else {
  Analytics.init()
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const App = () => {
  const routeNameRef = useRef()
  return (
    <SafeAreaView>
      <ThemeProvider>
        {Platform.OS === 'web' ? (
          <style type='text/css'>{`
          @font-face {
            font-family: 'MaterialCommunityIcons';
            src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
          }
        `}</style>
        ) : null}

        <NavigationContainer
          ref={navigationRef}
          // eslint-disable-next-line no-return-assign
          onReady={() =>
            (routeNameRef.current =
              navigationRef.current.getCurrentRoute().name)
          }
          onStateChange={async () => {
            const currentRouteName =
              navigationRef.current.getCurrentRoute().name
            await Analytics.logScreenView(currentRouteName)
            routeNameRef.current = currentRouteName
          }}
          linking={{ enabled: true }}
        >
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <AppNavigator />
              <Loader />
            </Provider>
          </QueryClientProvider>
        </NavigationContainer>
      </ThemeProvider>
    </SafeAreaView>
  )
}
