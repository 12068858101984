const awsConfig = {
  aws_project_region: 'ap-south-1',
  aws_appsync_graphqlEndpoint:
    'https://bqae3lhkprbe3pi7ypjbok65je.appsync-api.ap-south-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-south-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-whvhjtuu5jgitpbwbdure42rky',
}

export default awsConfig
