import React, { useState } from 'react'
import { useTheme } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

import { Login } from '../screens/login'
import { Success } from '../screens/success'

export const Stacks = (Stack) => {
  const [user, setUser] = useState(null)

  const { colors } = useTheme()
  const { t } = useTranslation()

  return (
    <Stack.Group>
      <Stack.Screen
        name='register'
        options={{
          title: 'Incresco - Contact us',
        }}
      >
        {(screenProps) => (
          <Login
            {...screenProps}
            user={user}
            colors={colors}
            t={t}
            setUser={setUser}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='success'
        options={{
          title: 'Incresco - Thank you',
        }}
      >
        {(screenProps) => (
          <Success
            {...screenProps}
            user={user}
            colors={colors}
            t={t}
            setUser={setUser}
          />
        )}
      </Stack.Screen>
    </Stack.Group>
  )
}
