import React, { useEffect, useState } from 'react'
import { BackgroundContainer } from '@nowon/container'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import BlurWidget from '@nowon/components/src/Blur'
import { UserDetails } from '../../components/onboard/userDetails'
import { updateUser } from '../../apiGraphql'

export const Login = () => {
  const isFocus = useIsFocused()
  const navigation = useNavigation()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [college, setCollege] = useState('')
  const [branch, setBranch] = useState('')
  const [socialProfile, setSocialProfile] = useState('')
  const [details, setDetails] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [gender, setGender] = useState('')
  const [reachMe, setReachMe] = useState('')
  const [showDropDown, setShowDropDown] = useState(false)

  useEffect(() => {
    if (isFocus === false) {
      setEmail('')
    }
  }, [isFocus])
  const handleUpdateProfile = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const response = await updateUser({
        branch,
        collegeName: college,
        details,
        email,
        mobileNumber,
        name,
        socialProfile,
        reachMe,
      })
      setIsLoading(false)
      if (response) navigation.navigate('success')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <BackgroundContainer>
      <>
        <BlurWidget borderRadius={4} />
        <UserDetails
          handleUpdateProfile={handleUpdateProfile}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          college={college}
          setCollege={setCollege}
          branch={branch}
          setBranch={setBranch}
          socialProfile={socialProfile}
          setSocialProfile={setSocialProfile}
          details={details}
          setDetails={setDetails}
          setShowDropDown={setShowDropDown}
          showDropDown={showDropDown}
          setGender={setGender}
          gender={gender}
          setReachMe={setReachMe}
          reachMe={reachMe}
        />
      </>
    </BackgroundContainer>
  )
}
