import React, { useState } from 'react'
import {
  useTheme,
  Text,
  Button,
  TextInput,
  Surface,
  HelperText,
} from 'react-native-paper'
import { StyleSheet, View, ScrollView } from 'react-native'
import { EmailTextInput } from '@nowon/components'
import { Dropdown } from 'react-native-element-dropdown'

export const UserDetails = (props) => {
  const {
    handleUpdateProfile,
    email,
    setEmail,
    name,
    setName,
    mobileNumber,
    setMobileNumber,
    college,
    setCollege,
    branch,
    setBranch,
    details,
    setDetails,
    setReachMe,
    reachMe,
  } = props
  const { colors } = useTheme()
  const [isFocus, setIsFocus] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isValidNumber, setIsValidNumber] = useState(true)
  const List = [
    {
      label: 'Casual Talk',
      value: 'Casual Talk',
    },
    {
      label: 'Hackathon',
      value: 'Hackathon',
    },
    {
      label: 'Career Advice',
      value: 'Career Advice',
    },
    {
      label: 'Startup Ideas',
      value: 'Startup Ideas',
    },
    {
      label: 'Internship',
      value: 'Internship',
    },
    {
      label: 'Campus Hiring',
      value: 'Campus Hiring',
    },
  ]

  const validate = (text) => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (reg.test(text) === false) {
      setEmail(text)
      setIsValidEmail(false)
      return false
    }
    setEmail(text)
    setIsValidEmail(true)
  }
  const validateMobile = (text) => {
    const reg = /^[6-9]\d{9}$/
    if (reg.test(text) === false) {
      setMobileNumber(text)
      setIsValidNumber(false)
      return false
    }
    setMobileNumber(text)
    setIsValidNumber(true)
  }

  return (
    <Surface
      elevation={0}
      style={{
        height: '100%',
        gap: 15,
        paddingTop: 10,
      }}
    >
      <ScrollView
        contentContainerStyle={{ paddingHorizontal: 32, paddingBottom: 30 }}
      >
        <Surface elevation={0} style={{ gap: 15 }}>
          <Surface elevation={0}>
            <Text
              variant='labelLarge'
              style={{
                color: colors.neutralLight,
              }}
            >
              Name *
            </Text>
            <TextInput
              mode='outlined'
              placeholder='Enter your name'
              style={{
                backgroundColor: colors.neutralLight,
                height: 40,
              }}
              placeholderTextColor={colors.onSurfacePlaceholder}
              theme={{
                colors: {
                  onSurface: colors.surface,
                },
              }}
              value={name}
              onChange={(value) => setName(value.target.value)}
            />
          </Surface>
          <Surface elevation={0}>
            <Text
              variant='labelLarge'
              style={{
                color: colors.neutralLight,
              }}
            >
              Email ID *
            </Text>
            <EmailTextInput
              mode='outlined'
              placeholder='Enter your Email ID'
              style={{
                backgroundColor: colors.neutralLight,
              }}
              value={email}
              onChangeText={(text) => validate(text.toLowerCase().trim())}
              onValidate={(isValid) => {
                console.log({ isValid })
              }}
              isValidEmail={isValidEmail}
            />
          </Surface>
          <Surface elevation={0}>
            <Text
              variant='labelLarge'
              style={{
                color: colors.neutralLight,
              }}
            >
              Mobile number
            </Text>
            <TextInput
              maxLength={10}
              mode='outlined'
              placeholder='Enter your Mobile number'
              style={{
                backgroundColor: colors.neutralLight,
                height: 40,
              }}
              placeholderTextColor={colors.onSurfacePlaceholder}
              theme={{
                colors: {
                  onSurface: colors.surface,
                },
              }}
              value={mobileNumber}
              onChange={(value) => validateMobile(value.target.value)}
            />
            {!isValidNumber && (
              <HelperText type='error'>Mobile Number is invalid!</HelperText>
            )}
          </Surface>
          <Surface elevation={0}>
            <Text
              variant='labelLarge'
              style={{
                color: colors.neutralLight,
              }}
            >
              College name *
            </Text>
            <TextInput
              mode='outlined'
              placeholder='Enter College name'
              style={{
                backgroundColor: colors.neutralLight,
                height: 40,
              }}
              placeholderTextColor={colors.onSurfacePlaceholder}
              theme={{
                colors: {
                  onSurface: colors.surface,
                },
              }}
              value={college}
              onChange={(value) => {
                setCollege(value.target.value)
              }}
            />
          </Surface>
          <Surface elevation={0}>
            <Text
              variant='labelLarge'
              style={{
                color: colors.neutralLight,
              }}
            >
              Branch/Semester *
            </Text>
            <TextInput
              mode='outlined'
              placeholder='Enter Branch'
              style={{
                backgroundColor: colors.neutralLight,
                height: 40,
              }}
              placeholderTextColor={colors.onSurfacePlaceholder}
              theme={{
                colors: {
                  onSurface: colors.surface,
                },
              }}
              value={branch}
              onChange={(value) => setBranch(value.target.value)}
            />
          </Surface>
          <Surface elevation={0}>
            <Text
              variant='labelLarge'
              style={{
                color: colors.neutralLight,
              }}
            >
              Interested in *
            </Text>
            <View style={{ backgroundColor: 'white', borderRadius: 7 }}>
              <Dropdown
                placeholder='Choose one'
                style={[styles.dropdown, isFocus && { borderColor: 'blue' }]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={List}
                maxHeight={300}
                labelField='label'
                valueField='value'
                value={reachMe}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={(item) => {
                  setReachMe(item.value)
                  setIsFocus(false)
                }}
              />
            </View>
            <TextInput
              mode='outlined'
              placeholder='Please add one or two lines about your interest'
              style={{
                backgroundColor: colors.neutralLight,
                height: 80,
              }}
              placeholderTextColor={colors.onSurfacePlaceholder}
              theme={{
                colors: {
                  onSurface: colors.surface,
                },
              }}
              multiline
              value={details}
              onChange={(value) => setDetails(value.target.value)}
            />
          </Surface>
          <Surface elevation={0} style={{ alignItems: 'flex-end' }}>
            <Button
              mode='contained'
              contentStyle={{ height: 48 }}
              disabled={
                !(email && name && college && branch && isValidEmail && reachMe)
              }
              style={{
                borderRadius: 24,
                width: '40%',
                backgroundColor:
                  email && name && college && branch && isValidEmail && reachMe
                    ? '#F94962'
                    : '#E4E2E6',
              }}
              labelStyle={{
                color:
                  email && name && college && branch && isValidEmail && reachMe
                    ? '#FFFFFF'
                    : '#77777A',
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 400,
              }}
              onPress={() => handleUpdateProfile()}
            >
              Submit
            </Button>
          </Surface>
        </Surface>
      </ScrollView>
    </Surface>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 16,
  },
  dropdown: {
    height: 50,
    borderColor: 'gray',
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: 'absolute',
    backgroundColor: 'white',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    backgroundColor: 'white',
  },
})
