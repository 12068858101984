import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import { store } from './store/storeProvider'

export const apiCall = async (queryFn, body, hasLoading = false) => {
  if (hasLoading) {
    store.dispatch({
      type: 'START_LOADING',
    })
  }
  const data = await API.graphql(graphqlOperation(queryFn, body))
  if (hasLoading) {
    store.dispatch({
      type: 'FINISH_LOADING',
    })
  }

  return data
}

export const updateUser = async (requestBody) => {
  const { data } = await apiCall(mutations.updateUser, requestBody, true)
  return data.updateUser
}
