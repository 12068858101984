import React from 'react'
import { View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'

export const EmailVerificationCard = () => {
  const { colors } = useTheme()
  return (
    <View
      style={{
        gap: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Text
        variant='headlineLarge'
        style={{ color: colors.textPrimary, textAlign: 'center' }}
      >
        Thank you for reaching out to us. We will get back to you shortly
      </Text>
    </View>
  )
}
