// Imports: Dependencies
import { combineReducers } from 'redux'

import loadingReducer from './loadingReducer'

// Redux: Root Reducer
const rootReducer = combineReducers({
  loading: loadingReducer,
})
// Exports
export default rootReducer
