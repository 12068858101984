// this is an auto generated file. This will be overwritten

export const updateUser = `
  mutation updateUser( 
    $branch: String!
    $collegeName: String!
    $email:String!
    $name:String!
    $socialProfile:String
    $mobileNumber:String
    $semester:String
    $details:String
    $reachMe:String
  ) {
    updateUser(branch: $branch,
     collegeName: $collegeName,
      email: $email, 
      name: $name,
      details: $details,
      mobileNumber: $mobileNumber, 
      semester: $semester, 
      socialProfile: $socialProfile, 
      reachMe: $reachMe){
        branch
        collegeName
        details
        email
        mobileNumber
        name
        reachMe
        semester
        socialProfile
        userId
    }
 }
 `
